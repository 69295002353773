import React, { ReactNode, useLayoutEffect, useState } from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { InfoBox } from './InfoBox';
import { useHistory } from 'react-router-dom';
import { themeColors } from 'common/themeColors';
import { SegmentService } from 'services/SegmentService';
import { Events } from 'models/SegmentEvents';
import { CurrentUser } from 'services/common/CurrentUser';
import { BuildingService } from 'services/BuildingService';
import { updateUser } from 'context/Reducer';
import { ZonedDateTime, ZoneOffset } from '@js-joda/core';
import { useOnboarding } from 'context/OnboardingProvider';

interface Props {
    title: string;
    textPrevious?: string;
    textNext?: string;
    index: number;
    disableBack?: boolean;
    stepInfo?: ReactNode;
    setActiveStep: React.Dispatch<any>;
    routes: any[];
}

const useStyles = makeStyles({
    root: {
        minWidth: 700,
        paddingTop: 40,
        marginLeft: 24,
        marginRight: 24,
    },
    stepTitle: {
        textAlign: 'center',
    },
    cardActions: {
        padding: 0,
    },
    prevNextButton: {
        padding: '24px 12px',
        flex: 1,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    infoCard: {
        paddingBottom: 0,
        paddingTop: 12,
        paddingLeft: 46,
        paddingRight: 46,
    },
    disabled: {
        backgroundColor: `${themeColors.light.DISABLED} !important`,
        color: 'white !important',
    },
});

export const OnboardingStep: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles(props);
    const currentUser = new CurrentUser();
    const buildingService = new BuildingService();
    const segmentService = new SegmentService();
    const [appState, dispatch] = useOnboarding();

    const {
        title,
        textPrevious,
        textNext,
        index,
        routes,
        disableBack,
        stepInfo,
        setActiveStep,
    } = props;

    const [formIdAction, setFormIdAction] = useState<{
        formId: string;
        action: () => Promise<any>;
    }>({ formId: null, action: null });
    const saveAndContinueActionWrapper = (
        id: string,
        saveAction: () => Promise<any>
    ) => {
        if (!formIdAction.action || formIdAction.formId !== id) {
            setFormIdAction({ formId: id, action: saveAction });
        }
    };

    const trackEvent = async (eventName) => {
        const buildings = await buildingService.getBuildings();
        const buildingIds = [];
        buildings.forEach((building) => {
            buildingIds.push(building.id);
        });
        segmentService.trackEvent(eventName, {
            User: currentUser.getUserId(),
            Account: currentUser.getAccountId(),
            Buildings: buildingIds,
        });
    };

    const [isNextDisabled, setIsNextDisabled] = useState(false);

    const children = React.Children.map(props.children, (child) => {
        const props = {
            onSave: saveAndContinueActionWrapper,
            setIsNextDisabled,
        };
        if (React.isValidElement(child)) {
            return React.cloneElement(child, props);
        }
    });

    useLayoutEffect(() => {
        // hooks equivalent of componentDidMount run...only want this to run when it renders
        setActiveStep(index);
    });

    const history = useHistory();
    const dateTime = ZonedDateTime.now(ZoneOffset.UTC);
    
    const goToPrevious = () => history.push(routes[index - 1].path);
    const goToNext = async () => {
        if (index === 0) {
            trackEvent(Events.COMPLETED_PROFILE);
        }
        if (index < routes.length - 1) {
            history.push(routes[index + 1].path);
            segmentService.trackPage(routes[index + 1].path);
        } else {
            if (!currentUser.getUserCreated()) {
                await updateUser(
                    dispatch,
                    { onboarded: true, userCreated: dateTime },
                    appState
                );
            }
            trackEvent(Events.COMPLETED_ONBOARDING);
            const state = sessionStorage.getItem('state');
            if (state) {
                sessionStorage.removeItem('state');
                const envPrefix = process.env.REACT_APP_ENV === 'prod' ? '' : 'stage-';
                window.location.href = `https://${envPrefix}auth.aquicore.com/continue?state=${state}`;
            } else {
                window.location.href = process.env.REACT_APP_UI;
            }
        }
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                className={classes.stepTitle}
                subheader={
                    <>
                        <Typography variant='overline'>
                            {index === routes.length - 1
                                ? 'Last step'
                                : `${index + 1} OF ${routes.length}`}
                        </Typography>
                        <Typography variant='h6'>{title}</Typography>
                    </>
                }
            />
            {stepInfo && (
                <CardContent className={classes.infoCard}>
                    {<InfoBox>{stepInfo}</InfoBox>}
                </CardContent>
            )}
            <CardContent>{children}</CardContent>
            <CardActions className={classes.cardActions}>
                <Grid container direction='row'>
                    <ButtonGroup disableElevation style={{ flex: 1 }}>
                        <Button
                            id='go-back-button'
                            fullWidth
                            variant='outlined'
                            className={classes.prevNextButton}
                            onClick={() => {
                                if (index !== 0) {
                                    setFormIdAction({
                                        formId: '',
                                        action: () => null,
                                    });
                                    goToPrevious();
                                }
                            }}
                            disabled={disableBack}
                        >
                            {textPrevious || 'Back'}
                        </Button>
                        <Button
                            id='save-and-continue-button'
                            fullWidth
                            color='primary'
                            variant='contained'
                            className={`${classes.prevNextButton} ${
                                isNextDisabled && classes.disabled
                            }`}
                            disabled={isNextDisabled}
                            onClick={() => {
                                if (formIdAction.action != null) {
                                    formIdAction
                                        .action()
                                        .then(() => {
                                            goToNext();
                                        })
                                        .catch(() => {});
                                } else {
                                    console.log(
                                        'WARNING: formIdAction.action not defined!'
                                    );
                                    goToNext();
                                }
                            }}
                        >
                            {textNext || 'Next'}
                        </Button>
                    </ButtonGroup>
                </Grid>
            </CardActions>
        </Card>
    );
};
