import { Singleton } from 'decorators/@Singleton';

@Singleton
export class CurrentUser {

    private accessToken!: string;
    private profileId!: number;
    private userId!: number;
    private propertyAccess!: string;
    private accountId!: number;
    private userCreated: string;

    public getAccessToken(): string {
        return this.accessToken || '';
    }

    public setAccessToken(accessToken: string): CurrentUser {
        this.accessToken = accessToken;
        return this;
    }

    public getProfileId(): number {
        return this.profileId;
    }

    public setProfileId(id: number): CurrentUser {
        this.profileId = id;
        return this;
    }

    public getUserId(): number {
        return this.userId;
    }

    public setUserId(id: number): CurrentUser {
        this.userId = id;
        return this;
    }

    public getPropertyAccess(): string {
        return this.propertyAccess;
    }

    public setPropertyAccess(access: string): CurrentUser {
        this.propertyAccess = access;
        return this;
    }
    
    public setAccountId(id: number): CurrentUser {
        this.accountId = id;
        return this;
    }

    public getAccountId(): number {
        return this.accountId;
    }
    
    public getUserCreated(): string {
        return this.userCreated;
    }

    public setUserCreated(userCreated: string): CurrentUser {
        this.userCreated = userCreated;
        return this;
    }
}
