import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { themeColors } from 'common/themeColors';
import { DraftsOutlined } from '@material-ui/icons';

interface Props {
    message?: string,
    className?: string
}

const useStyles = makeStyles({
    root: {
        height: 72,
        border: 'solid 1px rgba(66, 132, 67, 0.56)',
        borderRadius: 4,
        backgroundColor: 'rgba(66, 132, 67, 0.16)',
        padding: 8
    },
    icon: {
        backgroundColor: themeColors.dark.GREEN,
        color: themeColors.primary.WHITE,
        padding: 8,
        margin: 8,
        marginLeft: 16,
        marginRight: 16,
        borderRadius: '50%'
    }
});

export const InfoBox: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles(props);
    const { message, className, children } = props;

    return (
        <Grid className={className} container>
            <Grid
                item
                className={`${classes.root}`}
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <DraftsOutlined fontSize="large" className={classes.icon} />
                {children ? children : <Typography variant="body2">{message}</Typography>}
            </Grid>
        </Grid>
    )
};
