export const themeColors = {
    primary: {
        BLUE: '#1B76D6',
        GREEN: '#7ACF46',
        ORANGE: '#F7AD1E',
        PURPLE: '#A93C9A',
        RED: '#E41730',
        GRAY: '#979797',
        WHITE: '#FFF',
        BACKGROUND: '#D8D8D8'
    },
    light: {
        BLUE: '#28CBDC',
        GREEN: '#C4DF63',
        ORANGE: '#FFDA06',
        PURPLE: '#B97DD3',
        RED: '#FB6F71',
        GRAY: '#F1F1F1',
        CONCRETE: '#EBEBEB',
        GRAY_BORDER: '#C2C2C2',
        DISABLED: '#999999',
        SKY_BLUE: '#EDF4FC',
        BG_4: '#F5F5F5'
    },
    dark: {
        BLUE: '#1E59AE',
        GREEN: '#428443',
        ORANGE: '#FC7B33',
        PURPLE: '#533A7B',
        RED: '#A21621',
        GRAY: '#4D4D4D',
        MIDNIGHT: '#273241',
    }
};
