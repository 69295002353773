import { Singleton } from 'decorators/@Singleton';

type RegistryMap = Record<string, Function>;
@Singleton
export class LazyFactory {

    private registry: RegistryMap = { };

    public get(key: string): Function {
        return this.getRegistry()[key];
    }

    public getRegistry(): RegistryMap {
        return this.registry;
    }

    public register(key: string, fn: Function): LazyFactory {
        this.registry[key] = fn;
        return this;
    }

}
