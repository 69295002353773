import { PartialUser } from '../../models/PartialUser';
import { Profile } from '../../models/Profile';
import { Session } from '../../models/Session';
import { JsonObject, JsonProperty } from 'json2typescript';
import { SupportTeamRole, SupportTeamRoleConverter } from '../../models/SupportTeamRole';

@JsonObject('IdentityUserResponse')
export class IdentityUserResponse implements PartialUser {

    @JsonProperty('id', Number)
    public id: number;

    @JsonProperty('userType', String)
    public userType: string;

    @JsonProperty('email', String)
    public email: string;

    @JsonProperty('firstName', String)
    public firstName: string;

    @JsonProperty('currentProfile', Profile)
    public currentProfile: Profile;

    @JsonProperty('lastName', String)
    public lastName: string;

    @JsonProperty('userName', String)
    public userName: string;

    @JsonProperty('phoneNumber', String)
    public phoneNumber?: string;

    @JsonProperty('timeZoneId', String)
    public timeZoneId: string;

    @JsonProperty('image', String)
    public image: string;

    @JsonProperty('measurementSystem', String)
    public measurementSystem?: string;

    @JsonProperty('profiles', [Profile], true)
    public profiles?: Profile[] = void 0;

    @JsonProperty('language', String)
    public language?: string;

    @JsonProperty('title', String)
    public title?: string;

    @JsonProperty('supportTeamRole', String, SupportTeamRoleConverter)
    public supportTeamRole: SupportTeamRole;

    public session: Partial<Session>;

}