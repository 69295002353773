import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { CheckCircle, FiberManualRecord, FiberManualRecordOutlined } from '@material-ui/icons';
import { themeColors } from 'common/themeColors';

interface Props {
    completion: StepCompletion;
    position: StepPosition;
    title: string;
}

export enum StepCompletion {
    COMPLETE = 'COMPLETE',
    CURRENT = 'CURRENT',
    INCOMPLETE = 'INCOMPLETE'
}

export enum StepPosition {
    FIRST = 'FIRST',
    MID = 'MID',
    LAST = 'LAST'
}

const useStyles = makeStyles({
    root: {
    },
    complete: {
        '& > .icon': {
            color: themeColors.dark.GREEN
        }
    },
    incomplete: {

    },
    current: {
        '& > .title': {
            fontWeight: 'bold'
        }
    },
    first: {},
    mid: {},
    last: {},
    iconWrapper: {
        height: 24,
        paddingBottom: 12,
        paddingTop: 12
    },
    title: {
        height: 24,
        paddingBottom: 12,
        paddingTop: 12,
        paddingLeft: 12
    },
    leftBorder: {
        borderLeft: 'solid 1px #DADADA',
        marginRight: -12,
        paddingLeft: 12
    }
});

export const OverviewStep: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles(props);
    const { title, completion, position } = props;

    const getCompletionClass = (stepCompletion: StepCompletion) => {
        switch (stepCompletion) {
            case StepCompletion.COMPLETE:
                return classes.complete;
            case StepCompletion.CURRENT:
                return classes.current;
            case StepCompletion.INCOMPLETE:
                return classes.incomplete;
        }
    }

    const getCompletionIcon = (stepCompletion: StepCompletion) => {
        switch (stepCompletion) {
            case StepCompletion.COMPLETE:
                return <CheckCircle />;
            case StepCompletion.CURRENT:
                return <FiberManualRecord />;
            case StepCompletion.INCOMPLETE:
                return <FiberManualRecordOutlined />
        }
    }

    const getPositionClass = (stepPosition: StepPosition) => {
        switch (stepPosition) {
            case StepPosition.MID:
                return classes.mid;
            case StepPosition.FIRST:
                return classes.first;
            case StepPosition.LAST:
                return classes.last;
        }
    }

    const completionClass = getCompletionClass(completion);
    const icon = getCompletionIcon(completion);
    const positionClass = getPositionClass(position);

    return (
        <Grid container direction="row" alignItems="center" className={`${completionClass} ${positionClass}`}>
            <div className={`${classes.leftBorder} leftBorder`}></div>
            <div className={`${classes.iconWrapper} icon`}>{icon}</div>
            <div className={`${classes.title} title`}>{title}</div>
        </Grid>
    );
};
