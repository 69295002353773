import React from 'react';
import { FormControl, InputLabel, ListSubheader, makeStyles, Select, Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { themeColors } from 'common/themeColors';

interface Props {
    className?: string;
    label?: string;
    name: string;
    onChange?(): void;
    error?: boolean;
    info?: string;
    disabled?: boolean;
    control: any;
    callbackFn(): any[];
    renderValue?(value: any): any;
    multiple?: boolean;
    hasValue?: boolean;
    defaultValue?: any;
}

const useStyles = makeStyles({
    formControl: {
        margin: 8,
        display: 'flex'
    },
    adornmentIcon: {
        marginRight: -12
    },
    error: {
        color: themeColors.primary.RED
    },
    centerInputText: {
        paddingTop: 18,
        paddingBottom: 18,
    },
    subheader: {
        backgroundColor: themeColors.primary.WHITE
    }
});

export const FormSelectField: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles(props);
    const {
        className, name, label, control, error, callbackFn, disabled, renderValue, multiple, hasValue, defaultValue
    } = props;

    const labelId = `${name}-label`;
    const items = callbackFn();
    if (label) {
        const subheader = (
            // Prevents undefined value from being added to array if you click header
            // Yes you need both of these for some reason
            <div onClick={(e) => {
                e.stopPropagation();
            }}>
                <ListSubheader key={`${name}-subheader`} className={classes.subheader} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}>
                    <Typography variant="overline">{label}</Typography>
                </ListSubheader>
            </div>
        )
        items.splice(0, 0, subheader);
    }

    return (
        <FormControl
            variant="filled"
            error={error}
            className={`${classes.formControl} ${className}`}
            disabled={disabled}
        >
            <InputLabel id={labelId} className={hasValue ? 'MuiInputLabel-shrink' : ''}>{label}</InputLabel>
            <Controller
                as={
                    <Select
                        labelId={labelId}
                        label={label}
                        renderValue={renderValue}
                        multiple={multiple}
                    >
                        {items}
                    </Select>
                }
                defaultValue={defaultValue}
                name={name}
                control={control}
                error={error}
                inputProps={{
                    className: `${!label && classes.centerInputText}`
                }}
            />
        </FormControl>
    );
};
