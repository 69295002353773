import { JsonConverter, JsonCustomConvert } from 'json2typescript';

export enum SupportTeamRole {
    CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
    ENGAGEMENT_MANAGER = 'ENGAGEMENT_MANAGER',
    ENGAGEMENT_TEAM_MEMBER= 'ENGAGEMENT_TEAM_MEMBER',
    BSE = 'BSE',
    SOFTWARE_ENGINEER = 'SOFTWARE_ENGINEER',
    NONE = 'NONE'
}

@JsonConverter
export class SupportTeamRoleConverter implements JsonCustomConvert<SupportTeamRole> {

    public serialize(value: SupportTeamRole): string {
        return value.toString();
    }
    public deserialize(value: keyof typeof SupportTeamRole | '' | null | undefined): SupportTeamRole {
        return value === '' || value === null || value === void 0 ? SupportTeamRole.NONE : SupportTeamRole[value];
    }

}