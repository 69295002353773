import React, { useCallback, useEffect } from 'react';
import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import { FormTextField } from './common/form/FormTextField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormSelectField } from './common/form/FormSelectField';
import { Persona } from 'models/enums/UserPersona';
import { FormFunctionComponent } from 'models/common/FormFunctionComponent';
import { useOnboarding } from 'context/OnboardingProvider';
import { updateUser } from 'context/Reducer';
import { SegmentService } from 'services/SegmentService';
import { Events } from 'models/SegmentEvents';
import { FormSelectOrAddField } from './common/form/FormSelectOrAddField';

export interface RoleFormValues {
    portfolioName: string;
    role: string;
    title: string;
}

interface Props {
}

const useStyles = makeStyles({
    root: {
        paddingLeft: 26,
        paddingRight: 26,
        paddingBottom: 32
    },
    half: {
        width: '50%'
    },
    formControl: {
        margin: 8,
        display: 'flex'
    },
});

export const RoleForm: FormFunctionComponent<RoleFormValues, Props> = (props) => {
    const classes = useStyles(props);
    const { onSave } = props;
    const [state, dispatch] = useOnboarding();
    const buildingRole = state.permissions.find(
        role => role.id === parseInt(state.user.role));
    const defaultValues: RoleFormValues = {
        portfolioName: state.user.accountName || '',
        title: state.user.title || '',
        role: buildingRole ? buildingRole.name : ''
    };
    const userId = state.user.id;
    const validationSchema = yup.object<Partial<RoleFormValues>>({
        role: yup.string().required(' '),
        title: yup.string().required(' ')
    });
    const { handleSubmit, errors, control, reset, watch } = useForm<RoleFormValues>({
        mode: 'onSubmit',
        defaultValues,
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = useCallback((data: RoleFormValues) => {
        const submitData = { ...data };
        const segmentService = new SegmentService();
        const updateUserData = {
            ...state.user,
            ...submitData
        };
        segmentService.trackEvent(Events.COMPLETED_ROLE, {
            User: state.user.id,
            Account: state.user.accountId,
        });
        return updateUser(dispatch, updateUserData, state);
    }, [dispatch, state]);

    const onError = () => {
        return Promise.reject('role form validation error');
    };

    useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, state.permissions]);

    useEffect(() => {
        if (onSave && state.user.id) {
            onSave('RoleForm', handleSubmit(onSubmit, onError));
        }
    }, [handleSubmit, onSave, onSubmit, state.user]);

    const watchTitle = watch('title');

    return (
        <form className={classes.root}>
            <Grid container direction="row" >
                <Grid item xs id="PortfolioSelect">
                    <FormTextField
                        control={control}
                        name="portfolioName"
                        label="Portfolio Name"
                        disabled
                        error={!!errors.portfolioName}
                    />
                </Grid>
                <Grid item xs>
                    <FormSelectField
                        control={control}
                        name="role"
                        label="Role"
                        error={!!errors.role}
                        disabled
                        callbackFn={() => state.permissions.map(permission => (
                            <MenuItem key={permission.id} value={permission.name}>
                                {permission.name}
                            </MenuItem>
                        ))}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" className={classes.half}>
                <Grid item xs id="SelectJobTitle">
                    <FormSelectOrAddField
                        control={control}
                        name="title"
                        label="Job Title"
                        error={!!errors.title}
                        defaultValue={state.user.title}
                        options={Object.keys(Persona).map(key => Persona[key])}
                        hasValue={!!watchTitle}
                    />
                </Grid>
            </Grid>
        </form>
    )
};
