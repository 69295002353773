import { Inject } from 'decorators/@Inject';
import { Mapper } from '../models/common/mapping/Mapper';
import { Http } from './common/Http';
import { ResponseMapper } from './common/ResponseMapper';
import { RealUserResponse } from './models/UserResponse'
import { IdentityUserResponse } from 'services/models/IdentityUserResponse';
import { IdentityUser } from 'models/IdentityUser'
import { RealUser } from 'models/RealUser'
import { Permission } from 'models/Permissions'
export interface Role {
    id?: number;
    name: string;
    type?: string;
}

export interface Profile {
    id?: string;
    buildings: Number[];
    accountName?: string;
    hasFullVisibility?: boolean;
}

export class UserService {

    @Inject
    private origin: string;

    @Inject
    private mapper: Mapper;

    public async updateProfile(request:RealUser): Promise<RealUser> {
        try {
            const response = await Http.url(`${this.origin}/api/v3/users/${request.id}`)
            .withAuthz()
            .body(request)
                .PUT();
            return this.mapper.fromJson(response.body, RealUserResponse);
        } catch (error) {
            console.log(error);
            return ResponseMapper.mapper(error).mapToReject();
        }
    }

    public async getProfile(id:number): Promise<RealUser> {
        try {
            const response = await Http.url(`${this.origin}/api/v3/users/${id}`)
                .withAuthz()
                .GET();
            return this.mapper.fromJson(response.body, RealUserResponse);
        } catch (error) {
            console.log(error);
            return ResponseMapper.mapper(error).mapToReject();
        }
    }

    public async getMe(): Promise<IdentityUser> {
        try {
            const response = await Http.url(`${this.origin}/api/v3/identity/`)
            .withAuthz()
            .POST();
            return this.mapper.fromJson(response.body, IdentityUserResponse);
        } catch (error) {
            return ResponseMapper.mapper(error).mapToReject();
        }
    }
    public async getMyPermissions(accountId: number): Promise<Permission[]> {
        try {
            const response = await Http.url(`${this.origin}/api/v1/accounts/${accountId}/users/myPermissions?inflate=permissions,app`)
            .withAuthz()
            .GET();
            return this.mapper.fromJsonArray(response.body, Permission);
        } catch (error) {
            return ResponseMapper.mapper(error).mapToReject();
        }
    }
}
