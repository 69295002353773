export class ErrorResponse extends Error {

    constructor(
        headers: any,
        status: number,
        more: any,
        data: any
    ) {
        super('');
        this.headers = headers;
        this.status = status;
        this.more = more;
        this.data = data;
    }

    public headers: any;
    public status: number;
    public more: {
        url: string;
        method: string;
    };
    data: any;

}  