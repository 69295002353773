import { Inject } from 'decorators/@Inject';
import HttpStatus from 'http-status';
import { ContextualError } from './ContextualError';

export class ResponseMapper {

    private codes: string[];

    private response: Response;

    @Inject
    private env: string;

    public static mapper(response: Response): ResponseMapper {
        return new this(response);
    }

    private constructor(response: Response) {
        this.response = response;
        this.codes = new Array<string>();
    }

    public onStatus(code: number, message: string): ResponseMapper {
        this.codes[code] = message;
        return this;
    }

    public mapToError(): ContextualError {
        // This is why we never can see what is happening when this fails.
        console.error(this);
        const { response } = this;
        const error = new Error();

        if (!response.status && this.env !== 'prod') {
            return error;
        }

        const code = response.status;

        error.message = this.codes[code]
            ? this.codes[code]
            : HttpStatus[code];

        // @ts-ignore
        const { more } = response;
        more.responseStatus = response.status;

        (error as ContextualError).more = more;

        return error;
    }

    public mapToReject() {
        const mapped = this.mapToError();
        return Promise.reject(mapped);
    }

} 