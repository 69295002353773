import { LazyFactory } from 'di/Registry';

export const Inject = (target: any, key: string): any => {
    let val = target[key];

    if (delete target[key]) {
        const concrete = (new LazyFactory()).get(key);
        if (concrete) {
            val = concrete();
        }
        const getter = () => val;
        return Object.defineProperty(target, key, {
            get: getter,
            enumerable: true,
            configurable: true
        });
    }
    return null;
};
