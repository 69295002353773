import React from 'react';
import { FormControl, InputLabel, makeStyles } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { SelectOrAddField } from '../SelectOrAddField';

interface Props {
    label?: string;
    name: string;
    control: any;
    options: any[];
    defaultValue: any;
    hasValue?: boolean;
    error?: boolean;
}

const useStyles = makeStyles({
    formControl: {
        margin: 8,
        display: 'flex'
    }
});

export const FormSelectOrAddField: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles(props);

    const {
        name, label, control, error, hasValue, defaultValue, options
    } = props;

    return (
        <FormControl
            variant="filled"
            className={`${classes.formControl}`}
            error={error}
        >
            <InputLabel className={hasValue ? 'MuiInputLabel-shrink' : ''}>{label}</InputLabel>
            <Controller
                name={name}
                control={control}
                render={({ onChange }) => (
                    <SelectOrAddField
                        name={name}
                        error={error}
                        optionsLabel={label}
                        options={options}
                        defaultValue={defaultValue}
                        handleChange={(data) => onChange(data)}
                    />
                )}
            />
        </FormControl>
    )
};
