import { JsonObject, JsonProperty } from 'json2typescript';
import { RolePermissionResponse } from './RolePermissionResponse';

@JsonObject('RoleResponse')
export class RoleResponse {

    @JsonProperty('id', Number)
    public id: number;

    @JsonProperty('name', String)
    public name: string;

    @JsonProperty('permissions', [RolePermissionResponse])
    public permission: RolePermissionResponse[];
    
}