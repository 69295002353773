import React, { useState } from 'react';
import './App.css';
import './bootstrap.ts';
import { theme } from './config/theme';
import { Button } from '@material-ui/core';
import { LoginRenderer } from 'auth/LoginRenderer';
import {
    Auth0Context,
    Auth0Provider,
    Auth0ContextContent,
} from './auth/AuthService';
import { Grid, makeStyles, ThemeProvider, Typography } from '@material-ui/core';
import { OnboardingStep } from 'components/common/OnboardingStep';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
    Redirect,
} from 'react-router-dom';
import { themeColors } from 'common/themeColors';
import { RoleForm } from 'components/RoleForm';
import { ProfileForm } from 'components/ProfileForm';
import {
    OverviewStep,
    StepCompletion,
    StepPosition,
} from 'components/OverviewStep';
import { Loader } from 'components/common/Loader';
import { OnboardingProvider } from 'context/OnboardingProvider';

const useStyles = makeStyles({
    root: {
        height: '100%',
    },
    overviewPane: {
        maxWidth: 280,
        width: 280,
        backgroundColor: themeColors.primary.WHITE,
        padding: 24,
    },
    title: {
        paddingBottom: 12,
    },
    toolbarImage: {
        height: 40,
        width: 'auto',
        paddingBottom: 80,
    },
    logout: {
        paddingBottom: 24,
        position: 'fixed',
        bottom: '0',
    },
});

const routes = [
    {
        path: '/profile',
        exact: true,
        component: <ProfileForm />,
        title: 'Update your profile',
    },
    {
        path: '/role',
        component: <RoleForm />,
        title: 'Confirm your role',
        infoComponent: true,
    },
];

interface DefaultLandingPageProps {
    redirect?: boolean;
}


export const DefaultLandingPage: React.FunctionComponent<DefaultLandingPageProps> = (
    props: DefaultLandingPageProps
) => {
    const history = useHistory();
    history.push(routes[0].path);
    return <></>;
};

function App() {
    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get('state');
    if (state) {
        sessionStorage.setItem('state', state);
    }

    const classes = useStyles();
    const [step, setStep] = useState(0);

    return (
        <ThemeProvider theme={theme}>
            <Auth0Provider>
                <Auth0Context.Consumer>
                    {(authInfo: Auth0ContextContent) => {
                        return (
                            <div style={{ height: '100%' }}>
                                <OnboardingProvider>
                                    <Loader />
                                    <Router>
                                        <div style={{ height: '100%' }}>
                                            <Grid
                                                container
                                                direction='row'
                                                className={classes.root}
                                            >
                                                {authInfo.isAuthenticated && (
                                                    <Grid
                                                        item
                                                        className={
                                                            classes.overviewPane
                                                        }
                                                        container
                                                        direction='column'
                                                        justify='flex-start'
                                                        alignItems='flex-start'
                                                    >
                                                        <img
                                                            className={
                                                                classes.toolbarImage
                                                            }
                                                            src='/full-color-logomark.svg'
                                                            alt='Aquicore'
                                                        />
                                                        <Typography
                                                            variant='overline'
                                                            className={
                                                                classes.title
                                                            }
                                                        >
                                                            Let's get started
                                                        </Typography>
                                                        {routes.map(
                                                            (item, index) => {
                                                                let completion: StepCompletion;
                                                                if (
                                                                    index < step
                                                                ) {
                                                                    completion =
                                                                        StepCompletion.COMPLETE;
                                                                } else if (
                                                                    index ===
                                                                    step
                                                                ) {
                                                                    completion =
                                                                        StepCompletion.CURRENT;
                                                                } else {
                                                                    completion =
                                                                        StepCompletion.INCOMPLETE;
                                                                }
                                                                let position: StepPosition;
                                                                if (
                                                                    index === 0
                                                                ) {
                                                                    position =
                                                                        StepPosition.FIRST;
                                                                } else if (
                                                                    index ===
                                                                    routes.length -
                                                                    1
                                                                ) {
                                                                    position =
                                                                        StepPosition.LAST;
                                                                } else {
                                                                    position =
                                                                        StepPosition.MID;
                                                                }
                                                                return (
                                                                    <OverviewStep
                                                                        key={
                                                                            index
                                                                        }
                                                                        title={
                                                                            item.title
                                                                        }
                                                                        completion={
                                                                            completion
                                                                        }
                                                                        position={
                                                                            position
                                                                        }
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                        <Button
                                                            size='large'
                                                            color='primary'
                                                            className={
                                                                classes.logout
                                                            }
                                                            onClick={() =>
                                                                authInfo.logout(
                                                                    {
                                                                        returnTo: `${window.location.origin}`,
                                                                    }
                                                                )
                                                            }
                                                            id='logoutBtn'
                                                        >
                                                            logout
                                                        </Button>
                                                    </Grid>
                                                )}
                                                <Grid
                                                    item
                                                    xs
                                                    container
                                                    direction='column'
                                                    justify='center'
                                                    alignItems='center'
                                                >
                                                    <Switch>
                                                        {!authInfo.isAuthenticated && (
                                                            <Route
                                                                path={'/login'}
                                                                children={
                                                                    <LoginRenderer
                                                                        authInfo={
                                                                            authInfo
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        )}
                                                        {!authInfo.isAuthenticated && (
                                                            <Redirect
                                                                to={`/login?redirectUri=${window.location.pathname.split(
                                                                    '?'
                                                                )[0]
                                                                    }`}
                                                            />
                                                        )}
                                                        {routes.map(
                                                            (route, index) => {
                                                                return (
                                                                    <Route
                                                                        key={
                                                                            index
                                                                        }
                                                                        path={
                                                                            route.path
                                                                        }
                                                                        exact={
                                                                            route.exact
                                                                        }
                                                                        children={
                                                                            <OnboardingStep
                                                                                setActiveStep={
                                                                                    setStep
                                                                                }
                                                                                index={
                                                                                    index
                                                                                }
                                                                                title={
                                                                                    route.title
                                                                                }
                                                                                routes={
                                                                                    routes
                                                                                }
                                                                                disableBack={
                                                                                    index ===
                                                                                    0
                                                                                }
                                                                                stepInfo={
                                                                                    route.infoComponent &&
                                                                                        authInfo.isAuthenticated ? (
                                                                                        <Typography variant='body1' id='Invite_Message'>
                                                                                            You've
                                                                                            been
                                                                                            invited
                                                                                            to
                                                                                            join{' '}
                                                                                            <b>
                                                                                                {
                                                                                                    authInfo
                                                                                                        .currentIdentity
                                                                                                        .currentProfile
                                                                                                        .account
                                                                                                        .accountName
                                                                                                }
                                                                                            </b>
                                                                                        </Typography>
                                                                                    ) : null
                                                                                }
                                                                                textNext={
                                                                                    index ===
                                                                                        routes.length -
                                                                                        1
                                                                                        ? 'Finish'
                                                                                        : 'Save & Continue'
                                                                                }
                                                                            >
                                                                                {
                                                                                    route.component
                                                                                }
                                                                            </OnboardingStep>
                                                                        }
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                        <Route
                                                            children={
                                                                <DefaultLandingPage />
                                                            }
                                                        />
                                                    </Switch>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Router>
                                </OnboardingProvider>
                            </div>
                        );
                    }}
                </Auth0Context.Consumer>
            </Auth0Provider>
        </ThemeProvider>
    );
}

export default App;
