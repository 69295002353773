import { JsonObject, JsonProperty } from 'json2typescript';
import { CurrencyUnitConverter, CurrencyUnit } from './CurrencyUnit';

@JsonObject('Account')
export class Account {

    @JsonProperty('id', Number)
    public id: number;

    @JsonProperty('accountName', String)
    public accountName: string;

    @JsonProperty('currencyUnit', CurrencyUnitConverter, true)
    public currencyUnit: CurrencyUnit;

    @JsonProperty('imageUrl', String, true)
    public imageUrl: string;

}