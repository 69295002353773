import React, { createContext, Reducer, useReducer, useState } from 'react';
import { AppState } from './AppState';
import { initAppData, reducer } from './Reducer';

interface Props {}

const defaultState: AppState = {
    loading: false,
    user: {} as any,
    permissions: [],
    functionalities: [],
};

export const OnboardingStateContext = createContext<AppState>(defaultState);
export const OnboardingDispatchContext = createContext(undefined);

export const OnboardingProvider: React.FunctionComponent<Props> = (props) => {
    const { children } = props;
    const [initialized, setInitialized] = useState(false);
    const [state, dispatch] = useReducer<Reducer<AppState, any>>(
        reducer,
        defaultState
    );
    if (!initialized) {
        setInitialized(true);
        initAppData(dispatch, state);
    }
    return (
        <OnboardingStateContext.Provider value={state}>
            <OnboardingDispatchContext.Provider value={dispatch}>
                {children}
            </OnboardingDispatchContext.Provider>
        </OnboardingStateContext.Provider>
    );
};

export const useOnboardingState = () => {
    const context = React.useContext(OnboardingStateContext);
    if (context === undefined) {
        throw new Error(
            'useOnboardingState must be used within OnboardingProvider'
        );
    }
    return context;
};

export const useOnboardingDispatch = () => {
    const context = React.useContext(OnboardingDispatchContext);
    if (context === undefined) {
        throw new Error(
            'useOnboardingDispatch must be used within OnboardingProvider'
        );
    }
    return context;
};

export const useOnboarding: () => [AppState, any] = () => {
    return [useOnboardingState(), useOnboardingDispatch()];
};
