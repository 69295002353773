import { Inject } from 'decorators/@Inject';
import { Singleton } from 'decorators/@Singleton';
import { JsonConvert, OperationMode, ValueCheckingMode } from 'json2typescript';

@Singleton
export class Mapper {

    private converter: JsonConvert;

    @Inject
    private env;

    constructor() {
        this.converter = new JsonConvert();
        this.converter.operationMode = OperationMode.ENABLE;
        this.converter.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
        this.converter.valueCheckingMode = ValueCheckingMode.ALLOW_NULL; // never allow null
    }

    public fromJson<T extends object>(obj: object, type: { new(): T }, debug?: boolean): T {
        if (!obj) return void 0;
        if (Array.isArray(obj)) return void 0;
        if (debug) {
            this.converter.operationMode = OperationMode.LOGGING;
        }
        const result = this.converter.deserializeObject(obj, type);
        if (debug) {
            this.converter.operationMode = OperationMode.ENABLE;
        }
        return result;
    }

    public fromJsonArray<T extends object>(obj: object, type: { new(): T }, debug?: boolean): T[] {
        if (!obj) return void 0;
        if (!Array.isArray(obj)) return void 0;

        if (debug) {
            this.converter.operationMode = OperationMode.LOGGING;
        }
        const result = this.converter.deserializeArray(obj, type);
        if (debug) {
            this.converter.operationMode = OperationMode.ENABLE;
        }
        return result;
    }

}