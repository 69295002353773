import { JsonObject, JsonProperty } from "json2typescript";
import { BuildingPurpose } from "models/BuildingPurpose";
import { PurposeResponse } from "./PurposeResponse";

@JsonObject('BuildingPurposeResponse')
export class BuildingPurposeResponse implements BuildingPurpose {
    @JsonProperty('id', Number)
    public id: number;

    @JsonProperty('name', String)
    public name: string;

    @JsonProperty('purposes', [PurposeResponse])
    public purposes: PurposeResponse[] = void 0;

}
