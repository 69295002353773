import { JsonObject, JsonProperty } from 'json2typescript';
import { App } from './App';
import { PermissionTypes, PermissionTypesConverter } from './enums/PermissionTypes';

@JsonObject('Permission')
export class Permission {

    @JsonProperty('app', App)
    public app: App;

    @JsonProperty('access', PermissionTypesConverter, true)
    public access: PermissionTypes;
}