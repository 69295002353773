import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { themeColors } from 'common/themeColors';
import { FormTextField } from './common/form/FormTextField';
import { ColorSelector } from './common/ColorSelector';
import { MediaService } from 'services/MediaService';
import { LazyFactory } from 'di/Registry';
import { MediaResponse } from 'services/models/MediaResponse';
import { FormFunctionComponent } from 'models/common/FormFunctionComponent';
import { useOnboarding } from 'context/OnboardingProvider';
import { updateUser } from 'context/Reducer';

export interface ProfileFormValues {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    image: string;
    color: string;
}

interface Props {
};

const useStyles = makeStyles({
    root: {
        paddingLeft: 26,
        paddingRight: 26,
        paddingBottom: 32
    },
    textField: {
        margin: 8,
        display: 'flex'
    },
    avatar: {
        width: 116,
        height: 116,

    },
    avatarRow: {
        paddingLeft: 8,
        marginBottom: 48
    },
    avatarInitials: {
        fontSize: 46,
        margin: 0,
        padding: 0,
        color: themeColors.dark.GRAY
    },
    avatarUploadSection: {
        paddingLeft: '1.5rem'
    },
    chooseInfo: {
        marginBottom: 0,
        marginTop: '1rem'
    },
    halfWidth: {
        width: '50%'
    }
});

const getFirstLetter = (name: string) => {
    return name ? name.substring(0, 1).toUpperCase() : '';
}

const getInitials = (firstName: string, lastName: string) => {
    return `${getFirstLetter(firstName)}${getFirstLetter(lastName)}`;
}

export const ProfileForm: FormFunctionComponent<ProfileFormValues, Props> = (props) => {
    const classes = useStyles(props);
    const { onSave } = props;

    const [state, dispatch] = useOnboarding();
    const defaultValues: ProfileFormValues = {
        firstName: state.user.firstName || '',
        lastName: state.user.lastName || '',
        phoneNumber: state.user.phoneNumber || '',
        image: state.user.image || '',
        color: state.user.color || themeColors.primary.BACKGROUND
    };
    const userId = state.user.id;

    const initials = getInitials(defaultValues.firstName, defaultValues.lastName);
    const [stateInitials, setStateInitials] = useState(initials);

    const validationSchema = yup.object<Partial<ProfileFormValues>>({
        firstName: yup.string().required(' '),
        lastName: yup.string().required(' '),
    });
    const { register, handleSubmit, getValues, setValue, errors, watch, control, reset } = useForm({
        mode: 'onSubmit',
        defaultValues,
        resolver: yupResolver(validationSchema)
    });

    const updateInitials = () => {
        const values = getValues();
        const newInitials = getInitials(values.firstName, values.lastName);
        if (stateInitials !== newInitials) {
            setStateInitials(newInitials);
        }
    }

    useEffect(() => {
        register({ name: 'color', type: 'custom' });
        register({ name: 'image', type: 'custom' });
    });
    const colorWatch = watch('color');
    const photoUrlWatch = watch('image');

    const onSubmit = useCallback((data: ProfileFormValues) => {
        const updateUserData = {
            ...state.user,
            ...data
        };
        return updateUser(dispatch, updateUserData, state);
    }, [dispatch, state]);

    const onError = () => {
        return Promise.reject('profile form validation error');
    };

    useEffect(() => {
        reset(defaultValues);
        setStateInitials(getInitials(defaultValues.firstName, defaultValues.lastName));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    useEffect(() => {
        if (onSave && state.user.id) {
            onSave('ProfileForm', handleSubmit(onSubmit, onError));
        }
    }, [handleSubmit, onSave, onSubmit, state.user]);

    const avatarColorOptions = [
        themeColors.primary.BACKGROUND,
        themeColors.light.RED,
        themeColors.primary.ORANGE,
        themeColors.light.ORANGE,
        themeColors.primary.GREEN,
        themeColors.light.BLUE,
        themeColors.light.PURPLE
    ];
    const selectColor = (color: string) => {
        setValue('color', color);
    }

    const mediaService: MediaService = new LazyFactory().get('mediaService')() as any;

    const uploadProfilePhoto = async () => {
        const items = await mediaService.pickPhotos(userId).catch(() => [] as MediaResponse[]);
        if (items.length === 0) {
            return;
        }
        setValue('image', items[0].uri);
    }

    return (
        <div>
            <form className={classes.root}>
                <Grid container direction="row" className={classes.avatarRow}>
                    <Grid item>
                        <Avatar
                            alt="profile image"
                            src={photoUrlWatch}
                            className={classes.avatar}
                            style={{ backgroundColor: colorWatch }}
                        >
                            <Typography variant="h6" className={classes.avatarInitials}>{stateInitials}</Typography>
                        </Avatar>
                    </Grid>
                    <Grid item xs className={classes.avatarUploadSection} container direction="column" alignItems="flex-start">
                        <Button
                            variant="contained"
                            disableElevation
                            color="primary"
                            size="large"
                            onClick={() => uploadProfilePhoto()}
                        >
                            Upload Photo
                    </Button>
                        <Typography variant="subtitle2" className={classes.chooseInfo}>or choose a color</Typography>
                        <Grid item container direction="row">
                            <ColorSelector
                                colors={avatarColorOptions}
                                onSelect={selectColor}
                                initialColor={colorWatch}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item xs>
                        <FormTextField
                            control={control}
                            name="firstName"
                            label="First Name"
                            onChange={updateInitials}
                            error={!!errors.firstName}
                            onClear={() => {
                                updateInitials();
                            }}
                        />
                    </Grid>
                    <Grid item xs>
                        <FormTextField
                            control={control}
                            name="lastName"
                            label="Last Name"
                            onChange={updateInitials}
                            error={!!errors.lastName}
                            onClear={() => {
                                updateInitials();
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" className={classes.halfWidth}>
                    <Grid item xs>
                        <FormTextField
                            control={control}
                            name="phoneNumber"
                            label="Phone Number (Optional)"
                        />
                    </Grid>
                </Grid>
            </form>
        </div>

    );
};
