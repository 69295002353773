import React, { useState } from 'react';
import {
    ClickAwayListener,
    Grid,
    IconButton,
    InputAdornment,
    ListSubheader,
    makeStyles,
    MenuItem,
    Paper,
    Popper,
    TextField,
    Typography
} from '@material-ui/core';
import { ArrowDropDown, Check, Info } from '@material-ui/icons';
import { themeColors } from 'common/themeColors';

interface Props {
    name: string;
    optionsLabel: string;
    options: any[];
    noOptionsText?: string;
    getOptionLabel?(option: any): string;
    defaultValue: any;
    handleChange(option: string): any;
    label?: string;
    error?: boolean;
}

const useStyles = makeStyles({
    field: {
        margin: 0,
        display: 'flex',
        flex: 1
    },
    popper: {
        width: 300,
        zIndex: 1,
        fontSize: 13,
        backgroundColor: themeColors.primary.WHITE
    },
    inputBase: {
        padding: '4px 16px',
        display: 'flex'
    },
    iconSeacrhPadding: {
        paddingLeft: 8,
        paddingRight: 4
    },
    adornmentIcon: {
        marginRight: -12
    },
    error: {
        color: themeColors.primary.RED
    },
    selectedItem: {
        backgroundColor: 'rgba(27, 118, 214, 0.08)'
    },
    input: {
        backgroundColor: themeColors.light.BG_4
    }
});

export const SelectOrAddField: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles(props);
    const {
        name, options, defaultValue, handleChange, label, optionsLabel, error
    } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [value, setValue] = useState(defaultValue);
    const [newValue, setNewValue] = useState('');

    if (defaultValue !== undefined && value === undefined) {
        setValue(defaultValue);
        return;
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const selectValue = (val) => {
        setValue(val);
        handleChange(val);
    }

    const handleClose = () => {
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    if (!options.includes(value)) {
        options.splice(0, 0, value);
    }

    const open = Boolean(anchorEl);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Grid item xs style={{ position: 'relative' }}>
                <TextField
                    className={classes.field}
                    variant="filled"
                    label={label}
                    value={value}
                    error={error}
                    id="SelectAddInputField"
                    InputProps={{
                        className: classes.input,
                        endAdornment: (
                            <InputAdornment position="end">
                                {error && (
                                    <IconButton
                                        aria-label="error"
                                        className={`${classes.adornmentIcon} ${classes.error}`}
                                    >
                                        <Info />
                                    </IconButton>
                                )}
                                <ArrowDropDown />
                            </InputAdornment>
                        )
                    }}
                    onClick={handleClick}
                />
                <Popper
                    open={open}
                    anchorEl={anchorEl}
                    className={classes.popper}
                    popperOptions={{
                        modifiers: {
                            offset: { enabled: true, offset: '0,-56', },
                        }
                    }}
                >
                    <Paper>
                        <ListSubheader key={`${name}-subheader`}>
                            <Typography variant="overline">{optionsLabel}</Typography>
                        </ListSubheader>
                        {options.map((item, index) => (
                            <MenuItem
                                key={item}
                                onClick={() => {
                                    selectValue(item);
                                    handleClose();
                                }}
                                className={value === item ? classes.selectedItem : ''}
                                id={`option-${index}`}
                            >
                                {item}
                            </MenuItem>
                        ))}
                        <MenuItem>
                            <TextField
                                className={classes.field}
                                variant="filled"
                                label="Other"
                                id='selectAddInput'
                                value={newValue}
                                onChange={(event) => setNewValue(event.target.value)}
                                InputProps={{
                                    className: classes.input,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                disabled={!newValue}
                                                onClick={() => {
                                                    selectValue(newValue);
                                                    setNewValue('');
                                                    handleClose();
                                                }}
                                            >
                                                <Check />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MenuItem>
                    </Paper>
                </Popper>
            </Grid>
        </ClickAwayListener>
    );
};
