import { JsonConverter, JsonCustomConvert } from 'json2typescript';

export enum Persona {
    ASSET_MANAGER = 'Asset Manager',
    PROPERTY_LEAD = 'Property Lead',
    PROPERTY_MANAGER = 'Property Manager',
    OPERATIONS_EXECUTIVE = 'Operations Executive',
    ENGINEERING_LEAD = 'Engineering Lead',
    BUILDING_ENGINEER = 'Building Engineer',
    SUSTAINABILITY_EXECUTIVE = 'Sustainability Executive'
}
@JsonConverter
export class PersonaConverter implements JsonCustomConvert<Persona> {

    public serialize(value: Persona): string {
        return value.toString();
    }
    public deserialize(value: keyof typeof Persona | '' | null | undefined): Persona {
        return value === '' || value === null || value === void 0 ? Persona.BUILDING_ENGINEER : Persona[value];
    }

}