import { PartialUser } from 'models/PartialUser';
import { JsonObject, JsonProperty } from 'json2typescript';
import { SupportTeamRole, SupportTeamRoleConverter } from 'models/SupportTeamRole';
import { Session } from 'models/Session';
import { User } from 'models/User';
import { Persona, PersonaConverter } from 'models/enums/UserPersona'
@JsonObject('Profile')
export class Profile {
    @JsonProperty('id', Number)
    public id: number;
    @JsonProperty('accountId', Number)
    public accountId: number;
    @JsonProperty('accountName', String)
    public accountName: String;
    @JsonProperty('portfolioUser', Boolean)
    public portfolioUser: Boolean;
    @JsonProperty('hasFullVisibility', Boolean)
    public hasFullVisibility: Boolean;
    @JsonProperty('invitationStatus', String)
    public invitationStatus: String;
    @JsonProperty('invitedByEmail', String)
    public invitedByEmail: String;
    @JsonProperty('profileId', Number)
    public profileId: Number;
    @JsonProperty('roles', [Number])
    public roles: [Number];
    @JsonProperty('user', User)
    public user: User;
    @JsonProperty('userId', Number)
    public userId: Number;
    @JsonProperty('visibilities', [Number])
    public visibilities: [Number];
}
@JsonObject('RealUserResponse')
export class RealUserResponse implements PartialUser {

    @JsonProperty('id', Number)
    public id: number;

    @JsonProperty('persona', String, PersonaConverter)
    public persona: Persona;

    @JsonProperty('userType', String, PersonaConverter)
    public userType: Persona;

    @JsonProperty('email', String)
    public email: string;

    @JsonProperty('firstName', String)
    public firstName: string;

    @JsonProperty('lastName', String)
    public lastName: string;

    @JsonProperty('userName', String)
    public userName: string;

    @JsonProperty('phoneNumber', String)
    public phoneNumber?: string;

    @JsonProperty('drillMode', String)
    public drillMode: string;

    @JsonProperty('defaultMetric', String)
    public defaultMetric: string;

    @JsonProperty('timeZoneId', String)
    public timeZoneId: string;

    @JsonProperty('image', String)
    public image: string;

    @JsonProperty('measurementSystem', String)
    public measurementSystem?: string;

    @JsonProperty('profiles', [Profile], true)
    public profiles?: Profile[] = void 0;

    @JsonProperty('currentProfile', Profile, true)
    public currentProfile: Profile = void 0;

    @JsonProperty('language', String)
    public language?: string;

    @JsonProperty('title', String)
    public title?: string;

    @JsonProperty('onboarded', Boolean)
    public onboarded: Boolean;

    @JsonProperty('supportTeamRole', String, SupportTeamRoleConverter)
    public supportTeamRole: SupportTeamRole;

    @JsonProperty('color', String, true)
    public color?: string;

    @JsonProperty('userCreated', String, true)
    public userCreated?: string;

    public session: Partial<Session>;

}
