import { Singleton } from 'decorators/@Singleton';
import { Http } from './common/Http';
import { Inject } from 'decorators/@Inject';
import { ResponseMapper } from './common/ResponseMapper';
import { Mapper } from '../models/common/mapping/Mapper';
import { RoleResponse } from 'services/models/RoleResponse';
import { Functionality } from 'models/Functionality';

@Singleton
export class AccountService {

    @Inject
    private origin: string;
    
    @Inject
    private mapper: Mapper;

    public async getAccountRole(userId: number, profileId: number): Promise<RoleResponse> {
        try {
            const response = await Http.url(`${this.origin}/api/v3/users/${userId}/profiles/${profileId}/role`)
            .withAuthz()
            .GET();
            return this.mapper.fromJson(response.body, RoleResponse);
        } catch (error) {
            console.log(error);
            return ResponseMapper.mapper(error).mapToReject();
        }
    }

    public async getFunctionalities(accountId: number): Promise<Functionality[]> {
        try {
            const response = await Http.url(`${this.origin}/api/v3/functionalities?accountId=${accountId}`)
            .withAuthz()
            .GET();
            return this.mapper.fromJsonArray(response.body, Functionality);
        } catch (error) {
            return ResponseMapper.mapper(error).mapToReject();
        }
    
    }
}
