import { Inject } from "decorators/@Inject";
import { Singleton } from "decorators/@Singleton";
import { Building } from "models/Building";
import { BuildingPurpose } from "models/BuildingPurpose";
import { Mapper } from "models/common/mapping/Mapper";
import { BuildingResponse } from "services/models/BuildingResponse";
import { Http } from "./common/Http";
import { ResponseMapper } from "./common/ResponseMapper";
import { BuildingPurposeResponse } from "./models/BuildingPurposeResponse";

@Singleton
export class BuildingService {

    @Inject
    private origin: string;

    @Inject
    private mapper: Mapper;

    public async getBuildings(): Promise<Building[]> {
        try {
            const response = await Http.url(`${this.origin}/api/v3/buildings?showInactive=false`)
            .withAuthz()
            .withProfile()
            .GET();
            return this.getMapper().fromJsonArray(response.body, BuildingResponse);
        } catch (error) {
            console.log(error);
            return ResponseMapper.mapper(error).mapToReject();
        }
    }

    public async getOnboardingBuildings(): Promise<Building[]> {
        try {
            const response = await Http.url(`${this.origin}/api/v3/buildings/get-onboarding-buildings`)
            .withAuthz()
            .withProfile()
            .GET();
            return this.getMapper().fromJsonArray(response.body, BuildingResponse);
        } catch (error) {
            console.log(error);
            return ResponseMapper.mapper(error).mapToReject();
        }
    }

    public async getBuildingPurposes(accountId: number): Promise<BuildingPurpose[]> {
        try {
            const response = await Http.url(`${this.origin}/api/v1/accounts/${accountId}/BuildingPurposes?inflate=purposes`)
            .withAuthz()
            .withProfile()
            .GET();

            return this.getMapper().fromJsonArray(response.body, BuildingPurposeResponse);
        } catch (error) {
            console.log(error);
            return ResponseMapper.mapper(error).mapToReject();
        }
    }

    public async updateBuildings(buildings: Building[]): Promise<Building[]> {
        try {
            const response = await Http.url(`${this.origin}/api/v3/buildings/update-onboarding-buildings`)
            .withAuthz()
            .withProfile()
            .body(buildings)
            .PUT();

            return this.getMapper().fromJsonArray(response.body, BuildingResponse);
        } catch (error) {
            console.log(error);
            return ResponseMapper.mapper(error).mapToReject();
        }
    }

    public getMapper() {
        return this.mapper;
    }

}
