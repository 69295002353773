import { Http } from './common/Http';
import { Inject } from 'decorators/@Inject';
import { Mapper } from 'models/common/mapping/Mapper';
import { SegmentResponse } from 'models/Segment';
import { Singleton } from 'decorators/@Singleton';

declare global {
    interface Window { analytics: any}
}

@Singleton
export class SegmentService {

    @Inject
    private mapper: Mapper;

    public async getSegmentKey(): Promise<SegmentResponse> {
        try {
            const response = await Http.url(`${process.env.REACT_APP_ORIGIN}/api/v3/segment/onboarding-app-key`)
                .withAuthz().GET();
    
            return this.mapper.fromJson(response.body, SegmentResponse);
        } catch (e) {
            console.error(`error retrieving segment key:\n${JSON.stringify(e)}\n`);
            throw e;
        }
    }

    public async initSegment(segmentKey: string){
        var analytics = window.analytics = window.analytics || [];

        // If the real analytics.js is already on the page return.
        if (analytics.initialize) return;

        // If the snippet was invoked already show an error.
        if (analytics.invoked) {
            if (window.console && console.error) {
                console.error('Segment snippet included twice.');
            }
            return;
        }   

        // Invoked flag, to make sure the snippet
        // is never invoked twice.
        analytics.invoked = true;

        // A list of the methods in Analytics.js to stub.
        analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on',
            'addSourceMiddleware',
            'addIntegrationMiddleware',
            'setAnonymousId',
            'addDestinationMiddleware'
        ];

        // Define a factory to create stubs. These are placeholders
        // for methods in Analytics.js so that you never have to wait
        // for it to load to actually record data. The `method` is
        // stored as the first argument, so we can replay the data.
        analytics.factory = function(method){
            return function(){
                const args = Array.prototype.slice.call(arguments);
                args.unshift(method);
                analytics.push(args);
                return analytics;
            };
        };

        // For each of our methods, generate a queueing stub.
        for (let i = 0; i < analytics.methods.length; i++) {
            const key = analytics.methods[i];
            analytics[key] = analytics.factory(key);
        }

        // Define a method to load Analytics.js from our CDN,
        // and that will be sure to only ever load it once.
        analytics.load = function(key, options){
            // Create an async script element based on your key.
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://cdn.segment.com/analytics.js/v1/'
                + key + '/analytics.min.js';

            // Insert our script next to the first script element.
            var first = document.getElementsByTagName('script')[0];
            first.parentNode.insertBefore(script, first);
            analytics._loadOptions = options;
        };

        // Add a version to keep track of what's in the wild.
        analytics.SNIPPET_VERSION = '4.1.0';
        analytics.load(segmentKey);
        analytics.page();
    };

    public identifyUser(user: any) {
        window.analytics.identify(user.id, {
            name: `${user.firstName} ${user.lastName}`,
            role: user.role,
            email: user.email,
            title: user.title,
            userType: user.userType
        })
    }

    public trackPage(pageName: string, properties?: Record<string, any>) {
        if (window.analytics) {
            const integrations = {
                Amplitude: { session_id: new Date().getTime }
            };
            
            window.analytics.page(pageName, properties, integrations);
        }
    }

    public trackEvent(eventName: string, properties?: Record<string, any>) {
        if (window.analytics) {
            const integrations = {
                Amplitude: { session_id: new Date().getTime }
            };
            
            window.analytics.track(eventName, properties, integrations);
        }
    }
  
}