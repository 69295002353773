import React, { useState } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { Brightness1, CheckCircle } from '@material-ui/icons';

interface Props {
    colors: string[];
    onSelect(color: string): void;
    initialColor?: string;
}

const useStyles = makeStyles({
    icon: {
        height: 36,
        width: 36
    }
});

export const ColorSelector: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles(props);
    const { colors, onSelect, initialColor } = props;
    const [color, setColor] = useState<string>();

    return (
        <>
            {colors.map(item => (
                <IconButton
                    key={item}
                    style={{ marginLeft: -12 }}
                    size="medium"
                    onClick={() => {
                        setColor(item);
                        onSelect(item);
                    }}
                >
                    {item === (color || initialColor)
                        ? <CheckCircle htmlColor={item} className={classes.icon} />
                        : <Brightness1 htmlColor={item} className={classes.icon} />
                    }
                </IconButton>
            ))}
        </>
    )
};
