import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Functionality')
export class Functionality {

    @JsonProperty('id', Number)
    public id: number;

    @JsonProperty('function', String)
    public function: string;

    @JsonProperty('test', Boolean)
    public test: boolean;

}
