import axios, { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { ErrorResponse } from './ErrorResponse';
import { CurrentUser } from 'services/common/CurrentUser'
import { Inject } from 'decorators/@Inject';

export class Http {

    private url!: string;

    private _body!: any;

    private version!: string;

    private headers!: Record<string, string>;

    private method!: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

    @Inject
    private currentUser: CurrentUser;

    public static url(url: string): Http {
        return new Http(url);
    }

    private constructor(url: string) {
        this.url = url;
        this.headers = {};
        this.headers.Accept = 'application/json';
        this.headers['Content-Type'] = 'application/json';
    }

    public body(body: any): Http {
        this._body = body;
        return this;
    }

    public setHeaders(headers: Record<string, string>): Http {
        this.headers = headers;
        return this;
    }

    public putHeader(name: string, value: string): Http {
        this.headers[name] = value;
        return this;
    }

    public setCookies(cookies: string): Http {
        this.putHeader('Cookie', cookies);
        return this;
    }

    public addCookie(name: string, value: string): Http {
        const cookies = this.headers.Cookie;
        return this.putHeader('Cookie', `${cookies} ${name}=${value};`);
    }

    public withAuthz(): Http {
        return this.putHeader('Authorization', `Bearer ${this.currentUser.getAccessToken()}`);
    }

    public withUser(): Http {
        return this.putHeader('X-Aq-User', `Bearer ${this.currentUser.getUserId()}`);
    }

    public withProfile() {
        //TODO use selected profile
        return this.putHeader('X-Aq-Profile', `Bearer ${this.currentUser.getAccessToken()}`);
    }

    public withSpecificProfile(profileId: string) {
        return this.putHeader('X-Aq-Profile', `${profileId}`);
    }

    public GET(): Promise<MappedResponse> {
        const { url } = this;
        this.method = 'GET';

        const response = axios({
            method: 'get',
            url,
            headers: this.headers
        });

        return this.mapAxiosToEs2016Response(response);
    }

    public POST(): Promise<MappedResponse> {
        const { url } = this;
        this.method = 'POST';

        const response = axios({
            method: 'post',
            url,
            data: this._body,
            headers: this.headers
        });

        return this.mapAxiosToEs2016Response(response);
    }

    public PUT(): Promise<MappedResponse> {
        const { url } = this;
        this.method = 'PUT';

        const response = axios({
            method: 'put',
            url,
            data: this._body,
            headers: this.headers
        });

        return this.mapAxiosToEs2016Response(response);
    }

    public PATCH(): Promise<MappedResponse> {
        const { url } = this;
        this.method = 'PATCH';

        const response = axios({
            method: 'patch',
            url,
            data: this._body,
            headers: this.headers
        });

        return this.mapAxiosToEs2016Response(response);
    }

    public DELETE(): Promise<MappedResponse> {
        const { url } = this;
        this.method = 'DELETE';

        const response = axios({
            method: 'delete',
            url,
            headers: this.headers
        });

        return this.mapAxiosToEs2016Response(response);
    }

    private async mapAxiosToEs2016Response(promise: AxiosPromise): Promise<MappedResponse> {
        return promise
            .then((success: AxiosResponse) => {
                const { data, headers, status } = success;

                const response = {
                    body: data,
                    headers,
                    status
                };

                return response;
            })
            .catch((error: ExpandedAxiosErrorResponse) => {
                console.error('ERROR: ', error);
                if (!error.response) {
                    throw new Error(JSON.stringify(error, null, 2));
                }
                const { headers, status, data } = error.response;

                const more = {
                    url: this.url,
                    method: this.method
                };

                throw new ErrorResponse(headers, status, more, data);
            });
    }

}

export interface MappedResponse {
    body: any;
    headers: Record<string, string>;
    status: number;
}

interface ExpandedAxiosErrorResponse extends AxiosError {
    body: any;
}  