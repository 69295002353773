import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Role')
export class Role {

    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('name', String)
    name: string;

    @JsonProperty('type', String)
    type: string;

}