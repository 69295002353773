export const SINGLETON_KEY = Symbol('Unique Key');

export type Singleton<T extends new (...args: any[]) => any> = T & {
    [SINGLETON_KEY]: T extends new (...args: any[]) => infer I ? I : never;
};

// see singleton.md for use
// eslint-disable-next-line
export const Singleton = <T extends new (...args: any[]) => any>(type: T): T => new Proxy(type, {
    construct(target: Singleton<T>, argumentsList, newTarget): T {
        // Skip proxy for children
        if (target.prototype !== newTarget.prototype) {
            return Reflect.construct(target, argumentsList, newTarget);
        }
        if (!target[SINGLETON_KEY]) {
            target[SINGLETON_KEY] = Reflect.construct(target, argumentsList, newTarget);
        }
        return target[SINGLETON_KEY];
    }
});
