import { JsonObject, JsonProperty } from 'json2typescript';
import { Role } from './Role';
import { Account } from './Account';

@JsonObject('Profile')
export class Profile {

    @JsonProperty('id', Number)
    public id: number = void 0;

    @JsonProperty('roles', [Role])
    public roles: Role[] = [];

    @JsonProperty('hasFullVisibility', Boolean)
    public hasFullVisibility: boolean = void 0;

    @JsonProperty('account', Account)
    public account: Account = void 0;

    @JsonProperty('invitedByEmail', String)
    public invitedByEmail: string = void 0;

    @JsonProperty('invitationStatus', String)
    public invitationStatus: string = void 0;

}