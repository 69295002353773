import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('App')
export class App {

    @JsonProperty('id', Number)
    public id: number;

    @JsonProperty('name', String)
    public name: string;
}