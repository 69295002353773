import { createMuiTheme } from '@material-ui/core';
import { themeColors } from 'common/themeColors';

export const themeCustomSpacing = {
    MuiCardContentPadding: '0.75rem',
    MuiFormControlMarginBottom: '1rem',
    HeaderPaddingTop: '0.75rem',
    HeaderMarginBottom: '1.75rem',
    Subtitle1MarginBottom: '0.5rem',
    Subtitle2MarginBottom: '0.5rem'
};

export const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            laptop: 1025,
            lg: 1280,
            xl: 1920
        }
    },
    typography: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        allVariants: {
            color: themeColors.dark.GRAY
        },
        h1: {
            fontSize: '6rem',
            fontWeight: 300,
            lineHeight: '9rem',
            letterSpacing: '-0.09rem'
        },
        h2: {
            fontSize: '3.75rem',
            fontWeight: 300,
            lineHeight: '5.62rem',
            letterSpacing: '-0.03rem'
        },
        h3: {
            fontSize: '3rem',
            fontWeight: 'normal',
            lineHeight: '4.5rem',
        },
        h4: {
            fontSize: '2.12rem',
            fontWeight: 'normal',
            lineHeight: '3.18rem',
            letterSpacing: '0.015rem'
        },
        h5: {
            fontSize: '1.5rem',
            fontWeight: 'normal',
            lineHeight: '2.25rem',
            letterSpacing: '0.015rem'
        },
        h6: {
            fontSize: '1.245rem',
            fontWeight: 600,
            lineHeight: '1.185rem',
            letterSpacing: '0.015rem',
            paddingTop: themeCustomSpacing.HeaderPaddingTop,
            marginBottom: themeCustomSpacing.HeaderMarginBottom
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 'normal',
            lineHeight: '1.5rem',
            letterSpacing: '0.03rem'
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 'normal',
            lineHeight: '1.31rem',
            letterSpacing: '0.015rem'
        },
        subtitle1: {
            fontSize: '1rem',
            fontWeight: 'normal',
            lineHeight: '1.5rem',
            letterSpacing: '0.009rem',
            marginBottom: themeCustomSpacing.Subtitle1MarginBottom
        },
        subtitle2: {
            fontSize: '0.875rem',
            fontWeight: 600,
            lineHeight: '1.31rem',
            letterSpacing: '0.006rem',
            marginBottom: themeCustomSpacing.Subtitle2MarginBottom
        },
        button: {
            fontSize: '0.875rem',
            fontWeight: 600,
            lineHeight: '1.31rem',
            letterSpacing: '0.075rem',
            textTransform: 'uppercase'
        },
        caption: {
            fontSize: '0.75rem',
            fontWeight: 'normal',
            lineHeight: '1.125rem',
            letterSpacing: '0.02rem',
        },
        overline: {
            fontSize: '0.75rem',
            fontWeight: 600,
            lineHeight: '1.125rem',
            letterSpacing: '0.12rem',
            textTransform: 'uppercase'
        }
    },
    palette: {
        primary: {
            light: themeColors.light.BLUE,
            main: themeColors.primary.BLUE,
            dark: themeColors.dark.BLUE
        },
        secondary: {
            light: themeColors.light.RED,
            main: themeColors.primary.RED,
            dark: themeColors.dark.RED
        },
        error: {
            light: themeColors.light.RED,
            main: themeColors.primary.RED,
            dark: themeColors.dark.RED
        },
        warning: {
            light: themeColors.light.ORANGE,
            main: themeColors.primary.ORANGE,
            dark: themeColors.dark.ORANGE
        },
        info: {
            light: themeColors.light.BLUE,
            main: themeColors.primary.BLUE,
            dark: themeColors.dark.BLUE
        },
        success: {
            light: themeColors.light.GREEN,
            main: themeColors.primary.GREEN,
            dark: themeColors.dark.GREEN
        },
        background: {
            default: themeColors.primary.GRAY
        }
    },
    overrides: {
        MuiCardContent: {
            root: {
                padding: themeCustomSpacing.MuiCardContentPadding
            }
        },
        MuiFilledInput: {
            root: {
                '&.Mui-disabled': {
                    backgroundColor: themeColors.light.CONCRETE
                }
            }
        },
        MuiFormControl: {
            root: {
                marginBottom: themeCustomSpacing.MuiFormControlMarginBottom
            }
        },
        MuiMenuItem: {
            root: {
                paddingTop: 12,
                paddingBottom: 12
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: 40
            }
        },
        MuiSvgIcon: {
            root: {
                width: 24,
                height: 24,
            }
        },
        MuiSnackbarContent: {
            root: {
                color: 'white'
            }
        },
        MuiInputLabel: {
            shrink: {
                fontWeight: 700
            }
        }
    }
});

declare module '@material-ui/core/styles/createBreakpoints' {
    interface BreakpointOverrides {
        laptop: true;
    }
}
