import { JsonObject, JsonProperty } from "json2typescript";
import { Purpose } from "models/BuildingPurpose";

@JsonObject('PurposeResponse')
export class PurposeResponse implements Purpose {

    @JsonProperty('id', Number)
    public id: number;

    @JsonProperty('name', String)
    public name: string;

}
