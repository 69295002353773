import * as filestack from 'filestack-js';
import { Singleton } from 'decorators/@Singleton';
import { Inject } from 'decorators/@Inject';
import { MediaResponse } from 'services/models/MediaResponse';

export interface FilestackUploadResult {
    filename: string;
    handle: string;
    mimetype: string;
    originalPath: string;
    size: number;
    source: string;
    url: string;
    uploadId: string;
    originalFile: {
        name: string;
        type: string;
        size: number;
    };
    status: string;
    key: string;
    container: string;
}

export interface MediaPickerResult {
    filesUploaded: FilestackUploadResult[];
    filesFailed: any[];
}

@Singleton
export class MediaService {

    private static readonly MAX_FILESIZE = {
        value: 1 * 1024 * 1024,
        label: '1MB'
    };

    @Inject
    private uploadS3Bucket;

    @Inject
    private uploadAwsRegion;

    @Inject
    private filestackAPIKey;

    private filestackClient: filestack.Client;

    private getS3Path(userId: number): string {
        return `user-${userId}/`;
    }

    constructor() {
        this.filestackClient = filestack.init(this.filestackAPIKey);
    }

    public pickPhotos(userId: number): Promise<MediaResponse[]> {
        const promise = new Promise<MediaResponse[]>((resolve, reject) => {
            const uploadS3Path = this.getS3Path(userId);
            const options: filestack.PickerOptions = {
                maxFiles: 1,
                accept: [
                    'image/*'
                ],
                cleanupImageExif: {
                    keepOrientation: true,
                    keepICCandAPP: true
                },
                // @ts-ignore
                onFileSelected: (file) => {
                    if (file.size > MediaService.MAX_FILESIZE.value) {
                        throw new Error(`File too big, needs to be smaller than ${MediaService.MAX_FILESIZE.label}`);
                    }
                },
                storeTo: {
                    container: this.uploadS3Bucket,
                    path: uploadS3Path,
                    region: this.uploadAwsRegion
                },
                fromSources: [
                    'local_file_system',
                    'imagesearch'
                ],
                uploadInBackground: false,
                onCancel: () => {
                    reject();
                },
                onUploadDone: (res: MediaPickerResult) => {
                    if (res.filesUploaded && res.filesUploaded.length > 0) {
                        const files: MediaResponse[] = res.filesUploaded.map((file) => {
                            const fileResponse: MediaResponse = {
                                name: (file.originalFile && file.originalFile.name) || '',
                                uri: file.url
                            };
                            return fileResponse;
                        });
                        resolve(files);
                    } else {
                        reject(new Error('Error while uploading files'));
                    }
                }
            };
            const picker = this.filestackClient.picker(options);
            return picker.open();
        });

        return promise;
    }

}
