import React, { useState } from 'react';
import {
    IconButton,
    InputAdornment,
    makeStyles,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { Close, Info, InfoOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import { Controller, UseFormMethods } from 'react-hook-form';
import { themeColors } from 'common/themeColors';

interface Props {
    className?: string;
    label?: string;
    name: string;
    control: any;
    onChange?(): void;
    onClear?(): void;
    error?: boolean;
    info?: string;
    clear?: boolean;
    disabled?: boolean;
    suffix?: string;
    defaultValue?: string | number;
    type?: string;
    readOnly?: boolean;
    prefixIcon?: JSX.Element;
    placeholder?: string;
}

const useStyles = makeStyles({
    textField: {
        margin: 8,
        display: 'flex',
        overflow: 'hidden',
    },
    adornmentIcon: {
        marginRight: -12,
    },
    error: {
        color: themeColors.primary.RED,
    },
    suffix: {
        backgroundColor: themeColors.primary.BACKGROUND,
        paddingRight: 28,
        paddingLeft: 16,
        paddingTop: 26,
        paddingBottom: 10,
        marginLeft: 12,
        marginRight: -12,
        lineHeight: '1.1876em',
    },
    centerInputText: {
        paddingTop: 18,
        paddingBottom: 18,
    },
    input: {
        backgroundColor: (props: Props) => props.readOnly ? themeColors.light.CONCRETE : themeColors.light.BG_4
    },
});

export declare type FormTextFieldContext = Pick<
    UseFormMethods<any>,
    'register' | 'setValue'
>;

export const FormTextField: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles(props);
    const [pwdVisible, setPwdVisible] = useState(false);
    const {
        className,
        name,
        label,
        control,
        error,
        info,
        clear,
        disabled,
        suffix,
        defaultValue,
        type,
        readOnly,
        prefixIcon,
        onChange,
        onClear,
        placeholder,
    } = props;

    const isPasswordType = type === 'password';

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={(props) => (
                <TextField
                    id={name}
                    className={`${classes.textField} ${className ? className : ''}`}
                    variant='filled'
                    label={label}
                    value={props.value}
                    onChange={(e) => {
                        props.onChange(e);
                        if (onChange) {
                            onChange();
                        }
                    }}
                    error={error}
                    disabled={disabled}
                    type={isPasswordType && pwdVisible ? 'text' : type}
                    inputProps={{
                        name: props.name,
                        readOnly: readOnly,
                        className: `${!label && classes.centerInputText} no-number-arrows`,
                        autoComplete: 'new-password',
                        min: 0,
                    }}
                    placeholder={placeholder}
                    InputProps={{
                        className: classes.input,
                        startAdornment: prefixIcon && (
                            <InputAdornment position='start'>
                                {prefixIcon}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <>
                                <InputAdornment position='end'>
                                    {error && (
                                        <IconButton
                                            aria-label='error'
                                            className={`${classes.adornmentIcon} ${classes.error}`}
                                        >
                                            <Info />
                                        </IconButton>
                                    )}
                                    {isPasswordType && props.value !== '' &&  (
                                        <IconButton
                                            aria-label='show/hide password'
                                            className={classes.adornmentIcon}
                                            onClick={() => setPwdVisible(!pwdVisible)}
                                        >
                                            {pwdVisible ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    )}
                                    {info && (
                                        <Tooltip title={info}>
                                            <IconButton
                                                aria-label='info'
                                                className={classes.adornmentIcon}
                                            >
                                                <InfoOutlined />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {(clear || onClear) && (
                                        <IconButton
                                            tabIndex='-1'
                                            aria-label='clear'
                                            className={classes.adornmentIcon}
                                            onClick={() => {
                                                control.setValue(name, '', {
                                                    shouldValidate: false,
                                                });
                                                if (onClear) {
                                                    onClear();
                                                }
                                            }}
                                        >
                                            <Close />
                                        </IconButton>
                                    )}
                                    {suffix && (
                                        <Typography
                                            variant='body1'
                                            className={`${classes.suffix} ${!label ? classes.centerInputText : ''}`}
                                        >
                                            {suffix}
                                        </Typography>
                                    )}
                                </InputAdornment>
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};
