import { JsonObject, JsonProperty } from 'json2typescript';
import { PermissionTypes, PermissionTypesConverter } from 'models/enums/PermissionTypes';

@JsonObject('RolePermissionResponse')
export class RolePermissionResponse {

    @JsonProperty('id', Number)
    public id: number;

    @JsonProperty('app', String)
    public app: string;

    @JsonProperty('access', PermissionTypesConverter, true)
    public access: PermissionTypes;
}