import { JsonConverter, JsonCustomConvert } from 'json2typescript';

export enum CurrencyUnit {
    USD = 'USD',
    USD_CENT = 'USD_CENT',
    PESOS = 'PESOS',
    PESOS_CENTAVO = 'PESOS_CENTAVO',
    EURO = 'EURO',
    EURO_CENT = 'EURO_CENT'
}

@JsonConverter
export class CurrencyUnitConverter implements JsonCustomConvert<CurrencyUnit> {

    serialize(value: CurrencyUnit): string {
        return value.toString();
    }
    deserialize(value: string): any {
        return CurrencyUnit[value];
    }

}