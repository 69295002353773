import { LazyFactory } from 'di/Registry';

const r = require;

// This file exists to run code before the rest of the application starts
// TODO: turn this into an array that you add module paths to
// TODO: convert services to have default exports so we don't need a specific classname after
//  the new (...).className();

// NOTE: The LazyFactory Singleton uses dynamic require statements for these modules because the
// load order is important. If these were not dynamically imported, nested dependencies through
// @Inject would break.

new LazyFactory()
    .register('env', () => process.env.REACT_APP_ENV)
    .register('origin', () => process.env.REACT_APP_ORIGIN)
    .register('apiOrigin', () => process.env.REACT_APP_APIORIGIN)
    .register('currentUser', () => new (r('services/common/CurrentUser')).CurrentUser())
    .register('filestackAPIKey', () => process.env.REACT_APP_FILESTACK_API_KEY)
    .register('uploadS3Bucket', () => process.env.REACT_APP_UPLOAD_S3_BUCKET)
    .register('uploadAwsRegion', () => process.env.REACT_APP_UPLOAD_AWS_REGION)
    .register('mediaService', () => new (r('services/MediaService')).MediaService())
    .register('userService', () => new (r('services/UserService')).UserService())
    .register('buildingService', () => new (r('services/BuildingService')).BuildingService())
    .register('segmentService', () => new(r('services/SegmentService')).SegmentService())
    .register('mapper',() => new (r('models/common/mapping/Mapper')).Mapper())
