import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('BuildingResponse')
export class BuildingResponse {
    @JsonProperty('id', Number)
    public id: number;

    @JsonProperty('address', String)
    public address: string;

    @JsonProperty('city', String)
    public city: string;

    @JsonProperty('state', String)
    public state: string;

    @JsonProperty('zipCode', String)
    public zipCode: string;

    @JsonProperty('name', String)
    public name: string;

    @JsonProperty('sizeInSqFt', Number)
    public sizeInSqFt: number;

    @JsonProperty('purpose', Number)
    public purpose: number;

    @JsonProperty('status', String)
    public status: string;

}
