import Typography from '@material-ui/core/Typography';
import { themeColors } from 'common/themeColors';
import React, { FunctionComponent } from 'react';
import {
    CircularProgress, createStyles, Grid, makeStyles, Theme
} from '@material-ui/core';
import { useOnboardingState } from 'context/OnboardingProvider';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(40, 50, 75, .4)',
        zIndex: 9999,
        height: '100%',
        width: '100%'
    },
    progress: {
        color: themeColors.primary.BLUE,
        margin: theme.spacing(2)
    },
    message: {
        textAlign: 'center',
        fontWeight: 'bold',
        color: 'white',
    }
}));

export const Loader: FunctionComponent = () => {
    const classes = useStyles();
    const state = useOnboardingState();

    return <div id="overall-loader">{state.loading ? (
        <div className={classes.root} >
            <Grid item>
                <CircularProgress
                    disableShrink
                    className={classes.progress}
                    size={70}
                    thickness={4}
                />
            </Grid>
            <Grid item>
                <Typography
                    component="p"
                    variant="subtitle1"
                    color="inherit"
                    align="center"
                    noWrap
                    className={classes.message}
                >
                    Loading...
                </Typography>
            </Grid>
        </div>
    ) : <div id='finished-loading' />}</div>
};
