import { Singleton } from 'decorators/@Singleton';
import {
    Auth0Client,
    GetTokenSilentlyOptions,
    GetIdTokenClaimsOptions,
    LogoutOptions,
} from '@auth0/auth0-spa-js';
import createAuth0Client from '@auth0/auth0-spa-js';
import { Component, createContext } from 'react';
import * as React from 'react';
import { User } from './User';
import { Http } from 'services/common/Http';
import { ClientMetaData } from 'models/common/ClientMetaData';
import { UserService } from 'services/UserService';
import { CurrentUser } from 'services/common/CurrentUser';
import { Inject } from 'decorators/@Inject';
import { SegmentService } from 'services/SegmentService';
import { Events } from 'models/SegmentEvents';
import { IdentityUser } from 'models/IdentityUser';
import LogRocket from 'logrocket';

export const auth0Prefix = 'https://api.aquicore.com';
// this is how we do it in the auth/config.ts since the site is statically hosted

export interface Auth0ContextContent {
    isLoading: boolean;
    isAuthenticated: boolean;
    user: any;
    currentIdentity: IdentityUser | null;
    getTokenSilently: (options: GetTokenSilentlyOptions) => Promise<any>;
    getIdTokenClaims: (options: GetIdTokenClaimsOptions) => any;
    logout: (options: any) => void;
    saveAccessToken: (token: string) => void;
    getInitials: () => string;
    getClientMetaData: () => Promise<ClientMetaData>;
}

export const Auth0Context = createContext({});

interface ProviderProps {}
interface ProviderState {
    auth0Instance: Auth0Client | null;
    isLoading: boolean;
    isAuthenticated: boolean;
    user: User | null;
    currentIdentity: IdentityUser | null;
}

export const AQ_USER_TOKEN = 'aq_user_token';

@Singleton
export class Auth0Provider extends Component<ProviderProps, ProviderState> {
    @Inject
    private currentUser: CurrentUser;

    @Inject
    public userService: UserService;

    @Inject
    private segmentService: SegmentService;

    constructor(props) {
        super(props);
        this.state = {
            auth0Instance: null,
            isLoading: true,
            isAuthenticated: false,
            user: null,
            currentIdentity: null,
        };
        this.initializeAuth0 = this.initializeAuth0.bind(this);
        this.logout = this.logout.bind(this);
        this.getInitials = this.getInitials.bind(this);
    }

    async componentDidMount() {
        const metaData = await this.getClientMetaData();
        await this.initializeAuth0(metaData);
    }

    public async getClientMetaData(): Promise<ClientMetaData> {
        try {
            const response = await Http.url(
                `${process.env.REACT_APP_ORIGIN}/api/v3/identity/client-meta`
            ).GET();
            const clientMetaData: ClientMetaData = response.body;
            return clientMetaData;
        } catch (err) {
            console.error('Failure fetching auth client metadata');
            throw err;
        }
    }

    async initializeAuth0(metaData) {
        const auth0Instance = await createAuth0Client({
            ...metaData,
            realm: 'db-aquicore',
            cacheLocation: 'localstorage',
        });
        const auth_token = await this.getAuthToken(metaData, auth0Instance);
        const isAuthenticated =
            (await auth0Instance.isAuthenticated()) && !!auth_token;
        const user = isAuthenticated ? await auth0Instance.getUser() : null;
        if (auth_token) {
            this.saveAccessToken(auth_token);
            this.currentUser.setAccessToken(auth_token);
        }
        const currentIdentity: IdentityUser = isAuthenticated
            ? await this.userService.getMe()
            : null;

        if (currentIdentity) {
            this.currentUser.setUserId(currentIdentity.id);
            this.currentUser.setProfileId(currentIdentity.currentProfile.id);
            const permissions = await this.userService.getMyPermissions(
                currentIdentity.currentProfile.account.id
            );
            const propertyPermissionArray = permissions.filter(
                (permission) => permission.app.name === 'Property Settings'
            );

            let propertyPermission = 'NONE';
            if (propertyPermissionArray && propertyPermissionArray.length > 0) {
                propertyPermission = propertyPermissionArray[0].access;
            }
            user.propertyPermission = propertyPermission;
            this.currentUser.setAccountId(
                currentIdentity.currentProfile.account.id
            );
        }
        if (isAuthenticated) {
            const segmentKey = await this.segmentService.getSegmentKey();
            this.segmentService.initSegment(segmentKey.segmentKey);
            this.segmentService.trackEvent(Events.BEGIN_ONBOARDING, {
                User: this.currentUser.getUserId(),
                Account: currentIdentity.currentProfile.account.id,
            });
            this.segmentService.trackPage('Login');
            this.segmentService.identifyUser(currentIdentity);

            if (process.env.NODE_ENV === 'production') {
                LogRocket.init('g0myju/staging');

                const accountName = currentIdentity.currentProfile
                    ? currentIdentity.currentProfile.account.accountName
                    : 'undefined';
                LogRocket.identify(`${currentIdentity.id}`, {
                    name: `${currentIdentity.firstName} ${currentIdentity.lastName}`,
                    email: `${currentIdentity.email}`,
                    currentProfile: `${accountName}`,
                });
            }
        }
        this.setState({
            auth0Instance,
            isLoading: false,
            isAuthenticated,
            user,
            currentIdentity,
        });
    }

    async getAuthToken(metaData, auth0Instance): Promise<string> {
        try {
            const authToken = await auth0Instance.getTokenSilently({
                metaData,
                realm: 'db-aquicore',
                ignoreCache: true,
            });
            return authToken;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    saveAccessToken(token: string): void {
        localStorage.setItem(AQ_USER_TOKEN, token);
        sessionStorage.setItem(AQ_USER_TOKEN, token);
    }

    loadAccessToken(): string | null {
        const token =
            localStorage.getItem(AQ_USER_TOKEN) ||
            sessionStorage.getItem(AQ_USER_TOKEN);
        return token || null;
    }

    clearAccessToken(): void {
        return localStorage.removeItem(AQ_USER_TOKEN);
    }

    logout(options: LogoutOptions) {
        sessionStorage.clear();
        localStorage.clear();
        const client = this.state.auth0Instance;
        if (client) {
            client.logout(options);
        }
    }

    getInitials(): string {
        if (
            this.state.user &&
            this.state.user[`${auth0Prefix}/first_name`] &&
            this.state.user[`${auth0Prefix}/first_name`]
        ) {
            return (
                this.state.user[`${auth0Prefix}/first_name`][0] +
                this.state.user[`${auth0Prefix}/last_name`][0]
            );
        }
        return '';
    }

    render() {
        const {
            isLoading,
            isAuthenticated,
            user,
            auth0Instance,
            currentIdentity,
        } = this.state;
        if (auth0Instance === null) {
            return <div></div>;
        }
        const configObject: Auth0ContextContent = {
            isLoading,
            isAuthenticated,
            user,
            currentIdentity,
            getTokenSilently: (...p) => auth0Instance.getTokenSilently(...p),
            getIdTokenClaims: (...p) => auth0Instance.getIdTokenClaims(...p),
            logout: (options: LogoutOptions) => this.logout(options),
            saveAccessToken: this.saveAccessToken,
            getInitials: this.getInitials,
            getClientMetaData: this.getClientMetaData,
        };

        return (
            <Auth0Context.Provider value={configObject}>
                {this.props.children}
            </Auth0Context.Provider>
        );
    }
}
