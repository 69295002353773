import { UserService } from "services/UserService";
import { AccountService } from "services/AccountService";
import { AppState, UserState } from "./AppState";
import { CurrentUser } from 'services/common/CurrentUser'
import { RealUser } from "models/RealUser";
import { RoleResponse } from "services/models/RoleResponse";
import { SegmentService } from 'services/SegmentService';

export const reducer = (state: AppState, action): AppState => {
    switch (action.type) {
        case "UPDATE_USER":
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            };
        case "UPDATE_PERMISSIONS":
            return {
                ...state,
                permissions: [...action.payload],
            };
        case "SET_LOADING":
            return {
                ...state,
                loading: action.payload
            }
        case "FUNCTIONALITIES":
            return {
                ...state,
                functionalities: [...action.payload]
            }
        default:
            return state;
    }
};

export const initAppData = async (dispatch, state: AppState) => {
    const currentUser = new CurrentUser();
    if (!currentUser.getUserId()) {
        return;
    }
    dispatch({ type: "SET_LOADING", payload: true });

    await Promise.all([
        getUser(dispatch, state),
        getFunctionalities(dispatch, currentUser.getAccountId())
    ]);
    dispatch({ type: "SET_LOADING", payload: false });
}

export const getUser = async (dispatch, state: AppState): Promise<UserState> => {
    const userService: UserService = new UserService();
    const currentUser = new CurrentUser();
    if (!currentUser.getUserId()) {
        console.error('No current user is available');
        return;
    }
    if (state.user.id) {
        return state.user;
    }
    const userResponse = await userService.getProfile(currentUser.getUserId());
    const userCreated = userResponse.userCreated || '';
    currentUser.setUserCreated(userCreated);
    const user = formatUpdateProfile(userResponse);
    dispatch({ type: "UPDATE_USER", payload: user });
    if (state.permissions.length < 1) {
        await getPermissions(dispatch, userResponse.currentProfile.accountId)
    }
    return user
}

export const getPermissions = async (dispatch, accountId: number): Promise<RoleResponse[]> => {
    const accountService: AccountService = new AccountService();
    const currentUser = new CurrentUser();
    const userRoleResp: RoleResponse = await accountService.getAccountRole(currentUser.getUserId(), currentUser.getProfileId());
    const userRole: RoleResponse[] = [userRoleResp];
    await dispatch({ type: "UPDATE_PERMISSIONS", payload: userRole });
    return userRole;
}

export const getFunctionalities = async (dispatch, accountId: number) => {
    const accountService: AccountService = new AccountService();
    const functionalities = await accountService.getFunctionalities(accountId)
    await dispatch({ type: "FUNCTIONALITIES", payload: functionalities });
}

export const updateUser = async (dispatch, user, state: AppState) => {
    const userService: UserService = new UserService();
    const segmentService: SegmentService = new SegmentService();
    const updateRequest = {
        ...state.user.userUpdateRequest,
        ...user
    };
    // only identify when we submit the profile form 
    if (updateRequest && !updateRequest.portfolioName) {
        segmentService.identifyUser(updateRequest);
    }
    const userResponse = await userService.updateProfile(updateRequest);
    const updatedUser = formatUpdateProfile(userResponse);
    dispatch({ type: "UPDATE_USER", payload: updatedUser });
}

const formatUpdateProfile = (userResponse: RealUser): UserState => {
    const { accountName } = userResponse.currentProfile;
    const role = '' + userResponse.currentProfile.roles[0];
    return {
        id: userResponse.id,
        firstName: userResponse.firstName,
        lastName: userResponse.lastName,
        phoneNumber: userResponse.phoneNumber,
        accountName: `${accountName}`,
        accountId: userResponse.currentProfile.accountId,
        role: role,
        profileId: userResponse.currentProfile.id,
        permission: '',
        buildingPersona: userResponse.persona,
        title: userResponse.title,
        image: userResponse.image,
        color: userResponse.color,
        userUpdateRequest: userResponse
    };
}

