
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

export enum PermissionTypes {
    FULL_ACCESS="FULL_ACCESS",
    READ_ONLY="READ_ONLY",
    NONE="NONE",
    EDIT="EDIT"
}

@JsonConverter
export class PermissionTypesConverter implements JsonCustomConvert<PermissionTypes> {

    serialize(value: PermissionTypes): string {
        return value.toString();
    }
    deserialize(value: string): any {
        return PermissionTypes[value];
    }

}