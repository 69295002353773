import * as React from 'react';
import { Auth0ContextContent } from "./AuthService";
import Auth0Lock from 'auth0-lock';

export interface LoginRendererProps {
    authInfo: Auth0ContextContent;
}
    
export const LoginRenderer: React.FunctionComponent<LoginRendererProps> = (props) => {
    const { authInfo } = props;
    async function initAuth0Lock() {
        const clientMetaData = await authInfo.getClientMetaData();
        const lock = new Auth0Lock(clientMetaData.client_id, clientMetaData.domain, {
            container: 'login-container',
            auth: {
                redirect: false,
                responseType: 'token',
                params: {
                    scope: clientMetaData.scopes // Learn about scopes: https://auth0.com/docs/scopes
                },
            },
            configurationBaseUrl: 'https://cdn.auth0.com',
            languageDictionary: { title: '' },
            theme: {
                logo: 'https://my.aquicore.com/img/brand/full-color-logo.png',
                primaryColor: '#0091F1'
            },
            
        });
        lock.show();
        lock.on('authenticated', login);
        lock.on('hash_parsed', login);
        
    }

    function login(token: any) {
        if(token) {
            authInfo.saveAccessToken(token.accessToken);
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const redirect = urlParams.get('redirectUri');
            window.location.href = redirect;
        }
    }

    React.useLayoutEffect(() => {
        // react tells you to call async functions here this way
        async function init() {
            await initAuth0Lock();
        }
        init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authInfo]);
 

    return (
        <div id="login-container">
            Loading...
        </div>
    );
}